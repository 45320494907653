.shop-with-container {
  text-align: center;
  /* padding: 40px 20px; */
  font-family: "Arial", sans-serif;
}

.shop-with-title {
  font-size: 2.5rem;
  /* margin-bottom: 20px; */
  color: #333;
}

.shop-with-title .highlight {
  color: #007bff; /* Adjust color to match your design */
}

.shop-with-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 150px;
  background-color: #ffc107; /* Background color of the box */
  padding: 20px;
  border-radius: 10px;
  flex-wrap: wrap; /* Ensures wrapping on smaller screens */
}

.shop-with-logo img {
  max-width: 100px; /* Adjust size to fit logos */
  max-height: 100px;
  transition: transform 0.3s ease;
}

.shop-with-logo img:hover {
  transform: scale(1.1); /* Add a slight hover effect */
}

@media (max-width: 768px) {
  .shop-with-logo img {
    max-width: 80px; /* Resize for smaller screens */
  }
  .shop-with-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #ffc107; /* Background color of the box */
    padding: 20px;
    border-radius: 10px;
    flex-wrap: wrap; /* Ensures wrapping on smaller screens */
  }
}
