.testimonials-container {
    padding: 40px;
    text-align: center;
    font-family: "Arial", sans-serif;
  }
  
  .testimonials-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .testimonials-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  
  .testimonials-text {
    max-width: 500px;
    text-align: left;
  }
  
  .testimonials-text h3 {
    font-size: 1.8rem;
    font-weight: bold;
    color: #0056d2;
  }
  
  .testimonials-text p {
    margin: 10px 0;
    color: #555;
    font-size: 1rem;
  }
  
  .testimonials-image-wrapper {
    position: relative;
    max-width: 400px;
    width: 100%;
  }
  
  .testimonials-image {
    width: 100%;
    border-radius: 10px;
  }
  
  .testimonials-card {
    position: absolute;
    bottom: -20px;
    left: 10%;
    right: 10%;
    background: white;
    padding: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    text-align: left;
  }
  
  .testimonials-review {
    font-style: italic;
    margin-bottom: 10px;
  }
  
  .testimonials-name {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .testimonials-reviews {
    font-size: 0.9rem;
    color: #777;
  }
  
  .testimonials-rating {
    color: #ffa500;
    font-size: 1.2rem;
  }
  
  .arrow-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: white;
    border: 1px solid #ddd;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .arrow-left {
    left: -50px;
  }
  
  .arrow-right {
    right: -50px;
    /* margin-bottom: 100px; */
  }
  
  .arrow-button:hover {
    background: #f7f7f7;
  }
  
  @media (max-width: 768px) {
    .testimonials-wrapper {
      flex-direction: column;
    }
  
    .arrow-left {
      left: 10px;
    }
  
    .arrow-right {
      right: 10px;
    }
  }
  