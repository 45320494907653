/* .invoice-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .company-details,
  .customer-details,
  .product-details,
  .total-amount {
    margin-bottom: 20px;
  }
  
  .company-details h2,
  .customer-details h3,
  .product-details h3,
  .total-amount h3 {
    margin: 10px 0;
    font-size: 18px;
    color: #333;
  }
  
  .company-details p,
  .customer-details p,
  .total-amount p {
    margin: 5px 0;
    color: #555;
  }
  
  .product-details table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .product-details th,
  .product-details td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .product-details th {
    background-color: #f2f2f2;
  }
  
  .total-amount p {
    font-weight: bold;
  }
   */



   /* .invoice-container {
    font-family: Arial, sans-serif;
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .invoice-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #eee;
    padding-bottom: 10px;
  }
  .company-logo {
    width: 70px;
    height: auto;
    margin-right: 10px;
  }
  .company-info {
    display: flex;
    align-items: center;
  }
  
  
  .company-info h1 {
    color: #ff6600;
  }
  
  .invoice-details {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .invoice-items table {
    width: 100%;
    margin-top: 20px;
    border-collapse: collapse;
  }
  
  .invoice-items th, .invoice-items td {
    text-align: left;
    padding: 8px;
    border: 1px solid #ddd;
  }
  
  .invoice-summary {
    margin-top: 20px;
    text-align: right;
  }
  
  .invoice-footer {
    margin-top: 20px;
    font-size: 12px;
    text-align: center;
    color: #888;
  }
  
  .download-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #ff6600;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .download-button:hover {
    background-color: #e65c00;
  }
   */


   .invoice-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ddd;
    font-family: Arial, sans-serif;
    background-color: #fff;
  }
  
  .invoice-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .company-info {
    display: flex;
    align-items: center;
  }
  
  .company-info .logo {
    width: 60px;
    height: auto;
    margin-right: 15px;
  }
  
  .company-address {
    text-align: right;
  }
  
  .invoice-details,
  .invoice-items,
  .invoice-summary {
    margin-bottom: 20px;
  }
  
  .invoice-items table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .invoice-items th,
  .invoice-items td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .invoice-items th {
    background-color: #f4f4f4;
  }
  
  .invoice-summary div {
    text-align: right;
  }
  
  .download-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .download-button:hover {
    background-color: #0056b3;
  }
  