/* General Styles for Login Button */
.styled-login-button {
  background-color: #ffffff; /* White background */
  color: #000000; /* Blue text */
  font-size: 1.2rem; /* Adjust font size for better visibility */
  font-weight: bold; /* Bold text */
  /* font-family: "Chela one", sans-serif; Bold font family */
  border: none; /* Remove border */
  border-radius: 25px; /* Rounded corners */
  padding: 10px 30px; /* Padding for button dimensions */
  cursor: pointer; /* Pointer cursor on hover */
  transition: all 0.3s ease; /* Smooth transition effects */
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.5); /* Soft shadow effect */
}

/* Hover Effects */
.styled-login-button:hover {
  background-color: #c7cace; /* Blue background on hover */
  color: #ffffff; /* White text on hover */
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3), -4px -4px 12px rgba(255, 255, 255, 0.6); /* Enhanced shadow */
}

/* Focus Effects */
.styled-login-button:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 3px rgba(0, 122, 255, 0.5); /* Focus ring */
}
/* Container for login/profile button */
.login-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Styled Login Button */
.styled-login-button {
  background-color: #112850;
  color: #f5f1f1;
  font-size: 1rem;
  /* font-weight: bold; */
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.styled-login-button:hover {
  background-color: #ff8800;
  transform: scale(1.05);
}

/* Avatar button for Profile */
.avatar-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #007aff;
  transition: transform 0.2s ease, border-color 0.2s ease;
}

.avatar-button:hover {
  transform: scale(1.1);
  border-color: #0056cc;
}
