.features-container {
  padding: 40px 20px;
  text-align: justify;
  background-color: #ffffff;
}

.features-header h2 {
  font-size: 2.5rem;
  color: #333333;
  margin-bottom: 10px;
}

.features-header h2 .highlight {
  color: #007aff;
}

.features-header p {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 30px;
}

.features-row {
  display: flex;
  flex-direction: column; /* Stack the content vertically by default */
  align-items: center;
  gap: 40px;
  margin: 40px 0;
}

.features-row.reverse {
  flex-direction: column-reverse; /* Reverse the order of image and text for alternate rows */
}

.features-image {
  flex: 1;
  max-width: 500px;
  margin-right: 20px;
}

.features-image img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.features-content {
  flex: 1;
  max-width: 500px;
  /* text-align: left; */
  text-align: justify;
  
}

.features-content h3 {
  font-size: 1.8rem;
  color: #0056d2;
  margin-bottom: 15px;
}

.features-content ul {
  list-style: none;
  padding: 0;
  margin: 10;
}

.features-content ul li {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 1.1rem;
  color: #333;
  text-align: justify;
}

.features-content ul li .icon {
  margin-right: 10px;
  font-size: 1.5rem;
  color: #0056d2;
}

/* Responsive Design */
@media (min-width: 1024px) {
  .features-row {
    flex-direction: row; /* Switch to horizontal layout for larger screens */
  }

  .features-row.reverse {
    flex-direction: row-reverse; /* Reverse the order of image and text */
  }

  .features-image {
    margin-right: 20px; /* Add margin between image and text */
  }

  .features-content {
    text-align: justify; /* Align text to the left for larger screens */
  }
}

@media (max-width: 768px) {
  .features-row {
    flex-direction: column; /* Stack elements vertically on mobile */
  }
  .features-content {
    order: 1; /* Ensure heading and description appear first */
  }
  .features-image {
    order: 2; /* Image appears after heading and description */
    margin-top: 20px; /* Add spacing above the image */
  }

  .features-row.reverse {
    flex-direction: column-reverse; /* Ensure reverse works on mobile */
  }
}
