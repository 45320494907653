/* Swiper Container */
.banner-slider {
    width: 100%;
    height: 100vh; /* Full viewport height */
    overflow: hidden;
  }
  
  
  
  
  
  
  /* Swiper Slide */
  .banner-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Fill entire container height */
  }
  
  /* Image Section */
  .banner-image img {
    width: 100%;
    height: 100%; /* Fill container */
    object-fit: fill; /* Ensure the image covers the container without distortion */
    border-radius: 10px;
  }
  
  /* Swiper Pagination Dots */
  .swiper-pagination {
    position: absolute;
    bottom: 10px;
    text-align: center;
    width: 100%;
  }
  
  .swiper-pagination-bullet {
    background-color: #ddd;
    opacity: 1;
    width: 12px;
    height: 12px;
    margin: 0 5px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .swiper-pagination-bullet-active {
    background-color: #007aff; /* Active dot color */
  }
  .banner-media {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .banner-content {
    width: 100%;
    height: auto;
    object-fit: cover; /* Ensures consistent cropping for both images and videos */
    border-radius: 10px; /* Rounded corners for both images and videos */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .banner-slider {
      height: 30vh; /* Adjust height for smaller screens */
    }
  
    .banner-image img {
      height: 100%;
    }
  }
  