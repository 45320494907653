/* .profile-page {
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.profile-header {
  text-align: center;
  margin-bottom: 20px;
}

.profile-image img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #007bff;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.profile-details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.profile-field {
  display: flex;
  flex-direction: column;
}

.profile-field label {
  font-weight: bold;
  margin-bottom: 5px;
}

.editable-field {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 5px;
  font-size: 16px;
}

.editable-field:focus {
  outline: none;
  border-bottom: 1px solid #007bff;
}

.editable {
  color: #000;
}

.action-buttons {
  text-align: center;
  margin-top: 20px;
}

.edit-save-button,
.cancel-button,
.logout-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
  font-size: 16px;
}

.edit-save-button {
  background-color: #007bff;
  color: white;
}

.cancel-button {
  background-color: #ccc;
  color: black;
}

.logout-button {
  background-color: #ff4d4d;
  color: white;
  width: 100%;
}

.logout-button:hover {
  background-color: #e04343;
}

.editable-field {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 5px;
  width: 100%;
  font-size: 16px;
  background-color: transparent;
}

.readonly-field {
  font-size: 16px;
  color: #333;
  padding: 5px;
}
 */


 /* General Page Styling */
.profile-page {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  min-height: 100vh;
}
.edit-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.save-button, .cancel-button {
  padding: 8px 16px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.save-button {
  background-color: #007bff; /* Shallow blue */
  color: white;
}

.save-button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.cancel-button {
  background-color: #f0f0f0; /* Light gray for cancel */
  color: #007bff; /* Blue text */
  border: 1px solid #007bff;
}

.cancel-button:hover {
  background-color: #e6e6e6; /* Darker gray on hover */
}

/* Full-Width Banner */
.profile-banner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #007bff;
  color: white;
  padding: 20px 0;
  width: 100%;
}

.banner-icon {
  text-align: center;
  cursor: pointer;
  transition: transform 0.2s;
}

.banner-icon:hover {
  transform: scale(1.1);
}

.banner-icon span {
  display: block;
  margin-top: 5px;
  font-size: 16px;
  font-weight: bold;
}

/* Spacer Between Banner and Box */
.spacer {
  height: 20px;
}

/* Main Box Styling */
.profile-box {
  background-color: white;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1,
h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

/* Fields Layout */
.profile-fields,
.address-fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.field {
  display: flex;
  flex-direction: column;
}

.field label {
  font-weight: bold;
  color: #007bff;
  margin-bottom: 5px;
}

.field span {
  border-bottom: 1px solid #ccc;
  padding: 5px 0;
  font-size: 16px;
  color: #333;
}

/* Logout Button */
.logout-button {
  display: block;
  width: 100%;
  background-color: #ff4d4d;
  color: white;
  padding: 10px;
  margin-top: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.logout-button:hover {
  background-color: #e04343;
}
.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.edit-icon,
.save-icon {
  cursor: pointer;
  color: #007bff;
  transition: transform 0.2s;
}

.edit-icon:hover,
.save-icon:hover {
  transform: scale(1.1);
}

input {
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 5px;
  width: 100%;
  font-size: 16px;
  background-color: transparent;
}

input:focus {
  outline: none;
  border-bottom: 1px solid #007bff;
}
