/* Overall container styling */
.about {
  padding: 20px;
  background-color: #f4f4f4;
  font-family: Arial, sans-serif;
}

/* Center the heading */
.about-heading {
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 50px;
  margin-top: 0px;
  
}

.about-heading .highlight {
  color: #007bff; /* Highlight color */
}

/* Flexbox container for content and video */
.about-content {
  display: flex;
  flex-wrap: wrap; /* Ensure responsiveness */
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
}

/* Text content styles */
.about-text {
  flex: 1 1 45%; /* 45% width for text, responsive */
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
  text-align: justify; /* Justify the text */
}

.about-text p {
  margin: 0;
}

/* Video container styles */
.about-video {
  flex: 1 1 45%; /* 45% width for video, responsive */
  
}

.video-player {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Responsive design */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column; /* Stack content and video vertically */
    justify-content: flex-start; /* Align the items to the start */
  }

  .about-text, .about-video {
    flex: 1 1 100%; /* Take full width on small screens */
  }

  .about-text {
    margin-bottom: 20px; /* Space between text and video */
  }

  .about-video {
    margin-bottom: 20px; /* Space between video and any subsequent element */
  }
}
