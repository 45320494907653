.video-overlay-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  /* Video Section */
  .video-container {
    position:fixed;
    width: 100%;
    height: 50vh; /* Top 30% of the page height */
    cursor: pointer; /* Show pointer on hover for interaction */
    overflow: hidden;
  }
  
  .background-video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensure the video covers the entire container */
  }
  
  .video-overlay-image {
    position: absolute;
    bottom: 10px; /* Adjust for spacing */
    left: 50px; /* Adjust for spacing */
    width: 150px; /* Adjust the overlay image size */
  }
  
  .video-overlay-image img {
    width: 100%;
    height: auto;
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add shadow for better visibility */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .video-container {
      height: 30vh; /* Increase video height for smaller screens */
    }
  
    .video-overlay-image {
      width: 100px; /* Reduce the size of the overlay image on smaller screens */
    }
  }
  