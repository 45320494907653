/* General Styling */
.content-section {
  padding: 40px 20px;
}
/* Content Row */
.content-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .content-text {
    flex: 1 1 45%;
    font-size: 1rem;
    line-height: 1.6;
    color: #555;
    text-align: justify;
  }
  
  .content-video {
    flex: 1 1 45%;
  }
  
  .video-player {
    width: 100%;
    height: 315px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

.section-heading {
  font-size: 2.5rem;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.highlight {
  color: #007aff;
}

/* Video Gallery */
.video-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.video-card {
  width: 300px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.video-card:hover {
  transform: scale(1.05);
}

.video-title {
  font-size: 1.2rem;
  color: #333;
  margin: 10px 0;
  padding: 0 10px;
}

.video-thumbnail img {
  width: 100%;
  height: auto;
  display: block;
}

/* Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: 90%;
  max-width: 800px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.modal-video-player {
  width: 100%;
  height: 450px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-close-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007aff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.modal-close-btn:hover {
  background-color: #005bb5;
}

/* Responsive Design */
@media (max-width: 768px) {
  .modal-video-player {
    height: 250px;
  }
}
