/* General Section Styles */
.about {
  padding: 100px 30px;
  background-color: #ffffff;
  text-align: center; /* Centers the section content */
}

/* About Heading */
.about-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 30px;
  margin-top: 100px;
}

.highlight {
  color: #007aff; /* Blue highlight */
}

/* About Content Section */
.about-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
}

/* Left Section: Text */
.about-text {
  flex: 1 1 50%;
  text-align: left;
}

.about-text p {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  text-align: justify;
}

/* Right Section: Video */
.about-video {
  flex: 1 1 40%;
}

.video-player {
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Call-to-Action Section */
.cta-container {
  background-color: #ffd700;
  border-radius: 10px;
  padding: 20px;
  margin-top: 40px;
  display: flex; /* Use Flexbox for layout */
  justify-content: space-between; /* Push items to opposite sides */
  align-items: center; /* Align items vertically */
  gap: 10px; /* Add spacing between elements */
}

/* Left-Aligned Text */
.cta-text {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
  text-align: left; /* Ensures text alignment */
  flex: 1; /* Allow text to take available space */
}

/* Right-Aligned Button */
.cta-button {
  background-color: #007aff;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #0056cc;
}

.cta-icon {
  font-size: 1.5rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
  }

  .about-text {
    text-align: center; /* Center text for smaller screens */
  }

  .cta-container {
    flex-direction: column; /* Stack items vertically on smaller screens */
    align-items: flex-start; /* Align items to the left */
  }

  .cta-button {
    align-self: flex-end; /* Align button to the right in stacked layout */
    margin-top: 10px; /* Add spacing between text and button */
  }
}

@media (max-width: 480px) {
  .about-heading {
    font-size: 2rem; /* Adjust heading size for smaller screens */
  }

  .about-text p {
    font-size: 1rem; /* Adjust text size for smaller screens */
  }

  .cta-text {
    font-size: 1.2rem; /* Reduce text size in call-to-action */
  }

  .cta-button {
    font-size: 0.9rem; /* Adjust button size */
    padding: 8px 16px;
  }
}
