/* Games Container */
.games-container {
  padding: 40px;
  text-align: center;
  background-color: #ffffff;
}

/* Super Saver Button (Heading) */
.super-saver-button {
  margin-bottom: 20px; /* Space between heading and cards */
}

/* Swiper Buttons */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  color: white; /* White arrow color */
  border: none;
  border-radius: 50%; /* Circle button */
  padding: 10px;
  cursor: pointer;
  font-size: 8px;
}

.swiper-button-prev {
  left: 0px; /* Position to the left */
}

.swiper-button-next {
  right: 0px; /* Position to the right */
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: rgba(0, 0, 0, 0.7); /* Darker background on hover */
}

/* For Small Screens */
@media (max-width: 768px) {
  .swiper-button-prev,
  .swiper-button-next {
    font-size: 16px; /* Smaller size for arrows */
    padding: 8px;
  }
}


/* Responsive Design */
.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr)); /* Responsive grid */
  gap: 20px; /* Space between cards */
  justify-content: center; /* Center the grid */
}

@media (max-width: 768px) {
  .card-grid {
    grid-template-columns: 1fr; /* Show only one card in mobile view */
  }
}

@media (max-width: 480px) {
  .swiper-slide {
    width: 100%; /* Ensure single card occupies full width in smaller screens */
  }

  
}
