



/* General Container */
.home-container {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  
  width: 100%; /* Take up full viewport width */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove padding to eliminate any extra space */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
  padding-top: 0;
}

/* Background Image */
.background-image {
  max-width: 100%; /* Scale image to fit container */
  height: auto;
  position: relative; /* Default positioning for the background */
  /* z-index: 1; Layer below the foreground image */
  margin: 0; /* Remove all margins to eliminate extra space */
}
.image-section img {
  max-width: 90%;
  height: auto;
}


.image-section {
flex: 1;
display: flex;
justify-content: center;
align-items: center;
position: relative; /* Make this a positioning context */
}

/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .home-container {
    padding-top: 0; /* Remove top padding for mobile */
    height: auto; /* Allow the container to shrink to the image size */
  }

  .background-image {
    max-width: 100%; /* Full width scaling for mobile */
    height: auto; /* Maintain aspect ratio */
    margin: 0; /* Remove margins */
    padding: 0; /* Remove any padding */
  }
}







