

/* Apply font globally */
body {
    /* font-family: "Yuji Mai", serif; */
    margin: 0;
    padding: 0;
  }
  
  
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background: #ffde17;
    background: #e5e7eb; /* Initial transparent background */
    box-shadow: none; /* No shadow initially */
    border-radius: 0 0 10px 10px;
    position: sticky;
    top: 0;
    z-index: 1000;
    font-family: "Lakki Reddy", serif;
    transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  .navbar.scrolled {
    background: #ffde17; /* White opaque background */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow on scroll */
  }
  
  .navbar.hidden {
    transform: translateY(-100%); /* Hide Navbar */
  }
  
  /* Logo Section */
  
  
  .navbar-left .logo {
    height: 50px; /* Keeps the height fixed */
    transform: scale(1.5); /* Scales the logo to make it appear larger */
    transform-origin: center; /* Ensures the scaling happens from the center */
    display: block; /* Ensures it behaves like an image block */
  }
  
  /* Navigation Links */
  .nav-links {
    display: flex;
    gap: 20px;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-right: 50px;
  }
  
  .nav-links li a {
    color: #000000;
    text-decoration: none;
    font-size: 24px;
    
    cursor: pointer;
  }
  
  .nav-links li a:hover {
    text-decoration: underline;
  }
  
  
  /* Navigation Links */
  
  
  /* Buttons */
  .navbar-buttons {
    display:flex;
    
  }
  
  .fun-zone,
  .login {
    background-color: white;
    color: #333;
    padding: 8px 16px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    font-weight: bold;
    font-family: 'roboto', cursive;
  }
  
  .fun-zone {
    background-color: orange;
    color: white;
  }
  
  .fun-zone:hover {
    background-color: #ff8800;
  }
  
  .login:hover {
    background-color: #f0f0f0;
  }
  
  /* Mobile Menu */
  .menu-toggle {
    display: none; /* Hidden in desktop view */
    font-size: 24px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  .nav-menu.show {
    display: flex; /* Show menu when toggled */
    
  }
  
  .nav-links {
    flex-direction: row;
  }
  
  .navbar-buttons {
    flex-direction: row;
    gap: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .menu-toggle {
      display: block; /* Show toggle button on mobile */
      color: black;
    }
  
    .nav-menu {
      display: none; /* Hidden by default */
      flex-direction: column;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.623);
      padding: 20px 0;
      border-radius: 0 0 10px 10px;
      z-index: 999;
    }
  
    .nav-menu.show {
      display:inline-flex; /* Show menu when toggled */
    }
  
    .nav-links {
      flex-direction: column;
      gap: 20px;
      align-items: center;
      margin-left: 40px;
    
      
    }
    .nav-links li a {
      color: #ff8800;
      text-decoration: none;
      font-size: 26px;
      
      cursor: pointer;
    }
  
    .navbar-buttons {
      flex-direction: column;
      gap: 20px;
      /* margin-top: 20px; */
    }
    
  }
  