/* Footer Styles */
.footer {
    background-color: #1a1a2e;
    color: #fff;
    padding: 40px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
   
  }
  
  /* Logo and Tagline Section */
  .footer-logo-section {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    text-align: left; /* Align text to the left */
    flex-wrap: wrap;
  }
  
  .footer-logo {
    height: 70px;
  }
  
  .vertical-line {
    width: 1px;
    height: 50px;
    background-color: #fff;
  }
  
  .footer-tagline {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .footer-tagline h3 {
    font-size: 1.5rem;
    margin: 0;
  }
  
  .footer-tagline p {
    font-size: 0.9rem;
    margin: 0;
    color: #ccc;
  }
  
  /* Links Section */
  .footer-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
  
  .footer-links a {
    color: #ccc;
    text-decoration: none;
    font-size: 0.9rem;
  }
  
  .footer-links a:hover {
    color: #fff;
  }
  
  /* Copyright Section */
  .footer-copyright {
    font-size: 0.8rem;
    color: #ccc;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .footer-logo-section {
      flex-direction: row; /* Logo and text in the same row */
      justify-content: center;
      align-items: center;
      text-align: center; /* Center text for smaller screens */
      gap: 10px; /* Adjust gap for compact view */
    }
  
    .footer-logo {
      height: 50px; /* Reduce logo size for mobile */
    }
  
    .vertical-line {
      height: 40px; /* Adjust vertical line height */
    }
  
    .footer-tagline h3 {
      font-size: 1.2rem; /* Reduce heading size for mobile */
    }
  
    .footer-tagline p {
      font-size: 0.8rem; /* Reduce tagline size for mobile */
    }
  
    .footer-links {
      flex-direction: column;
      gap: 10px; /* Adjust spacing for stacked links */
    }
  }
  