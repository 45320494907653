/* OrderPage.css */

.order-page {
    font-family: Arial, sans-serif;
  }
  .no-data {
    text-align: center;
    font-size: 18px;
    color: #777;
    padding: 20px;
    border: 1px dashed #ddd;
    border-radius: 8px;
    margin-top: 20px;
  }
  
  .order-banner {
    background-color: #007bff;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
  }
  
  .banner-icon {
    display: inline-block;
    font-size: 30px;
    cursor: pointer;
    text-align: center;
  }
  
  .banner-icon span {
    display: block;
    margin-top: 5px;
  }
  
  .order-list {
    padding: 20px;
  }
  
  .order-box {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    align-items: center; /* Align items horizontally */
    justify-content: space-between; /* Spread content across the box */
    transition: box-shadow 0.2s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .order-box:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .order-item {
    display: flex;
    align-items: center;
    flex: 2; /* Item section takes more space */
  }
  
  .order-item img {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    border-radius: 4px;
    object-fit: cover;
  }
  
  .order-item p {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px; /* Limit the item name size */
  }
  
  .order-detail {
    flex: 1; /* Equal space for order details */
    font-size: 14px;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center; /* Center align each detail */
  }
  
  .order-total {
    color: #2d9d78;
  }
  
  .payment-status {
    color: #f39c12;
    font-weight: bold;
  }
  


  @media (max-width: 768px) {
    /* Hide Order ID and Total on mobile */
    .order-id, 
    .order-total {
      display: none;
    }
  
    /* Make the product image, name, and payment status center aligned */
    .order-item {
      flex-direction: column;
      align-items: center;
    }
  }