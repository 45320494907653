/* Login Page Styles */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
  box-sizing: border-box;
}

.login-card {
  display: flex;
  flex-direction: row;
  width: 800px;
  height: 500px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.login-card-image {
  flex: 1;
  background: #c6dee6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-card-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.login-card-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
}

.login-input {
  width: 100%;
  padding: 10px 0;
  margin-bottom: 15px;
  font-size: 1rem;
  border: none;
  border-bottom: 2px solid #ddd;
  outline: none;
  transition: border-color 0.3s ease;
}

.login-input:focus {
  border-bottom-color: #007aff;
}

.otp-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

.otp-input {
  width: 15%; /* Adjust to create space for six inputs */
  text-align: center;
  font-size: 1.5rem;
  border: none;
  border-bottom: 2px solid #ddd;
  outline: none;
  padding: 5px 0;
  transition: border-color 0.3s ease;
}

.otp-input:focus {
  border-bottom-color: #007aff;
}

.login-button {
  width: 100%;
  background-color: #007aff;
  color: white;
  padding: 10px 0;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #0056cc;
}

.terms-text,
.otp-sent-text {
  font-size: 0.9rem;
  color: #666;
  margin: 10px 0;
}

.error-text {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}
.otp-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
}

.otp-input {
  width: 15%;
  text-align: center;
  font-size: 1.5rem;
  border: none;
  border-bottom: 2px solid #ddd;
  outline: none;
  padding: 5px 0;
  transition: border-color 0.3s ease;
}

.otp-input:focus {
  border-bottom-color: #007aff;
}

.resend-text {
  font-size: 0.9rem;
  color: #666;
  margin-top: 10px;
}

/* Hide image on mobile view */
@media (max-width: 768px) {
  .login-card {
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .login-card-image {
    display: none; /* Hide image section */
  }

  .login-card-form {
    width: 90%;
    padding: 15px;
  }
}
