/* finalround.css */
@import "~@fortawesome/fontawesome-free/css/all.min.css";

/* General Section Styles */
.container {
  font-family: "Arial, sans-serif";
  color: #000000;
  padding: 20px;
  background-color: #ffffff;
}

.header {
  text-align: center;
  margin-bottom: 40px;
}

.title {
  font-size: 36px;
  color: #1E90FF;
  margin-bottom: 10px;
}

.desc {
  font-size: 18px;
  line-height: 1.6;
  color: #555;
}

/* Contact and Map Section */
.contact-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Contact Box */
.contact-box {
  flex: 1;
  max-width: 33%;
  background-color: #e7f5ff;
  color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
}

/* Contact Details */
.contact-details {
  text-align: left;
  margin-top: 10px;
}

.contact-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.contact-item {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: left;
}

.social-title {
  font-size: 20px;
  margin-top: 20px;
}

.social-list {
  list-style-type: none;
  padding: 0;
  text-align: left;
}

.social-item {
  font-size: 16px;
  margin-bottom: 5px;
}

.hours-title {
  font-size: 20px;
  margin-top: 20px;
}

.hours {
  font-size: 16px;
  text-align: left;
}

/* Map Box */
.map-box {
  flex: 2;
  max-width: 67%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.contact-link {
  color: #007aff; /* Link color */
  text-decoration: none; /* Removes underline */
  font-size: 1rem;
}

.contact-link:hover {
  text-decoration: underline; /* Underline on hover */
}
.contact-item i,
.social-item i {
  margin-right: 10px; /* Adds space between icon and text */
}

.contact-item a,
.social-item a {
  color: #000000; /* Link color */
  text-decoration: none; /* Removes underline */
  font-size: 1rem;
}

.contact-item a:hover,
.social-item a:hover {
  text-decoration: underline; /* Underline on hover */
}


/* Responsive Design */
@media (max-width: 1024px) {
  .contact-section {
    flex-wrap: wrap;
  }

  .contact-box {
    max-width: 100%;
  }

  .map-box {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .contact-section {
    flex-direction: column;
  }

  .contact-box {
    max-width: 100%;
  }

  .map-box {
    max-width: 100%;
  }
}
