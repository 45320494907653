.fun-zone-button {
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    font-weight: bold;
    border-radius: 20px;
    background-color: #112850;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .fun-zone-button:hover {
    background-color: #ff8800;
  }
  