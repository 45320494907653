/* Super Saver Button Styles */
.super-saver-button {
    display: inline-block;
    background-color: #ff0000; /* Red background */
    color: white;
    font-size: 2rem; /* Large size for desktop */
    font-weight: bold;
    padding: 15px 30px; /* More padding for desktop */
    border-radius: 20px;
    text-align: center;
    cursor: default; /* Makes it non-clickable */
    animation: shake 1.5s infinite; /* Adds the shake animation */
   
  }
  
  /* Shake Animation */
  @keyframes shake {
    0%, 100% {
      transform: translateX(0);
    }
    10%, 30%, 50%, 70%, 90% {
      transform: translateX(-5px);
    }
    20%, 40%, 60%, 80% {
      transform: translateX(5px);
    }
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .super-saver-button {
      font-size: 2rem; /* Smaller font size for mobile */
      padding: 10px 20px; /* Less padding for mobile */
    }
  }
  
  @media (max-width: 480px) {
    .super-saver-button {
      font-size: 1.5rem; /* Even smaller font size for very small screens */
      padding: 8px 16px; /* Adjust padding for small screens */
    }
  }
  