/* General Card Container */
.card-container {
  width: 300px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  position: relative;
  font-family: "Arial", sans-serif;
  margin: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left; /* Aligns all text to the left */
}

.card-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

/* Image Section */
.image-section {
  position: relative;
}

.card-image img {
  width: 100%;
  height: auto;
  display: block;
  border-bottom: 1px solid #ddd;
}

/* Offers Tag */
.offers-tag {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ff4d4d;
  color: #ffffff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 10;
}

/* Ratings */
.rating {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: bold;
  background-color: #fff;
  color: #333;
  padding: 5px 10px;
  border-radius: 15px;
  border: 1px solid #ddd;
}

.rating .star {
  color: #fbb034;
  margin-left: 5px;
}

/* Content Section */
.card-content {
  padding: 10px 15px;
}

.title-and-rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.card-content h3 {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

/* Description */
.description {
  font-size: 0.85rem;
  color: #555;
  line-height: 1.4;
  margin: 10px 0;
}

/* Price Section */
.price-section {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.current-price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #ff4d4d;
}

.old-price {
  font-size: 1rem;
  color: #999;
  text-decoration: line-through;
}

/* Buttons Section */
.card-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.try-now,
.buy-now {
  flex: 1;
  border: none;
  padding: 10px 0;
  border-radius: 20px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
}

.try-now {
  background-color: #007aff;
  color: #fff;
}

.try-now:hover {
  background-color: #0056cc;
}

.buy-now {
  background-color: #ffd700;
  color: #333;
}

.buy-now:hover {
  background-color: #e6b800;
}

/* Responsive Design */
@media (max-width: 768px) {
  .card-container {
    width: 90%;
    margin: 10px auto;
  }

  .title-and-rating {
    flex-direction: row; /* Ensure rating stays on the opposite side */
    justify-content: space-between;
  }

  .card-buttons {
    flex-direction: column;
    gap: 10px;
  }

  /* Adjust Price Section for Mobile View */
  .price-section {
    flex-direction: row; /* Stack prices vertically */
    align-items: flex-start; /* Align prices to the left */
  }

  .old-price {
    margin-top: 5px; /* Add space between current and old prices */
  }
}

@media (max-width: 480px) {
  .card-container {
    width: 100%;
  }

  .title-and-rating {
    flex-direction: row;
    justify-content: space-between;
  }

  .rating {
    margin-top: 0;
    margin-left: 0;
  }
}
