/* General styles */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
  }
  
  .order-details-container {
    width: 90%;
    max-width: 1200px;
    margin: 20px auto;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Header */
  h1 {
    text-align: center;
    color: #333;
    margin-bottom: 10px;
  }
  
  p {
    /* text-align: center; */
    color: #555;
  }
  
  /* Info container */
  .info-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
    flex-wrap: wrap;
  }
  
  .info-section {
    flex: 1;
    min-width: 300px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  h3 {
    margin-bottom: 10px;
    color: #555;
  }
  
  p {
    margin: 5px 0;
    color: #444;
  }
  
  /* Order Summary */
  .info-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .info-row p {
    margin: 0;
  }
  
  .info-row p:first-child {
    text-align: left;
  }
  
  .info-row p:last-child {
    text-align: right;
  }
  
  /* Product details */
  .product-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 20px;
    flex-wrap: wrap;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .product-image {
    flex: 0 0 150px;
    width: 150px;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
  }
  
  .product-details {
    flex: 1;
    color: #555;
  }
  
  .product-details h4 {
    margin: 0;
    color: #333;
  }
  
  .product-details p {
    margin-top: 10px;
  }
  .invoice-section {
    display: flex;
    justify-content: flex-end; /* Align the button to the right */
    margin-top: 20px; /* Optional: add some spacing between sections */
  }
  .status-tracker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    position: relative;
  }
  
  .status-item {
    text-align: center;
    flex: 1;
    position: relative;
  }
  
  .status-item:not(:last-child)::after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #ccc;
    position: absolute;
    top: 15%;
    right: -50%;
    z-index: 1;
    transform: translateY(-50%);
  }
  
  .status-item.active:not(:last-child)::after {
    background-color: #4caf50; /* Active line color */
  }
  
  .status-circle {
    width: 20px;
    height: 20px;
    background-color: #ccc;
    border-radius: 50%;
    margin: 0 auto;
    z-index: 2;
    position: relative;
  }
  
  .status-item.active .status-circle {
    background-color: #4caf50; /* Active circle color */
  }
  
  .status-item p {
    margin-top: 8px;
    font-size: 12px;
    color: #666;
  }
  
  .status-item.active p {
    font-weight: bold;
    color: #000; /* Active text color */
  }
  

  /* Responsive design */
  @media (max-width: 768px) {
    .info-container {
      flex-direction: column;
      gap: 10px;
    }
  
    .product-container {
      flex-direction: column;
      align-items: center;
    }
  
    .product-image {
      margin-bottom: 10px;
    }
  }
  