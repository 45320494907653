/* General Section Styles */
@import "~@fortawesome/fontawesome-free/css/all.min.css";

.contact {
  padding: 50px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.contact-heading {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
}

.highlight {
  color: #007aff;
}

/* Big Card Container */
.contact-card {
  display: flex;
  flex-wrap: nowrap; /* Ensure no wrapping of items */
  justify-content: space-between;
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
}

/* Left Card: Contact Information */
.contact-info-card {
  flex: 1 1 35%;
  background-color: #e7f5ff;
  border-radius: 10px;
  padding: 20px;
  color: #333;
  text-align: left;
  position: relative;
  min-width: 300px; /* Prevents collapsing on smaller containers */
}

.contact-info-card h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.contact-info-card p {
  margin-bottom: 20px;
  font-size: 1rem;
  color: #666;
}

.contact-info-card ul {
  list-style: none;
  padding: 0;
}

.contact-info-card li {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px; /* Space between icon and text */
}

.contact-info-card li i {
  color: #007aff;
  font-size: 1.2rem; /* Icon size */
}

.contact-icon {
  color: #007aff; /* Consistent color for all icons */
  font-size: 1.2rem;
}

/* Decorative Circles */
.decorative-circles {
  position: absolute;
  bottom: 10px;
  left: 20px;
  display: flex;
  gap: 15px; /* Increased spacing for better visibility */
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.blue-circle {
  background-color: #fc910f;
}

.light-blue-circle {
  background-color: #ffffff;
}

/* Right Side: Contact Form */
.contact-form-card {
  flex: 1 1 60%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  min-width: 300px; /* Prevents collapsing on smaller containers */
}
.contact-link {
  color: #000000; /* Blue for links */
  text-decoration: none;
  font-size: 1rem;
}

.contact-link:hover {
  text-decoration: underline;
}


.form-row {
  display: flex;
  gap: 10px;
}

input,
textarea {
  width: 100%;
  padding: 10px 0; /* Remove padding for top and bottom */
  font-size: 1rem;
  border: none;
  border-bottom: 2px solid #ddd; /* Underline style */
  outline: none; /* Remove outline */
  transition: border-color 0.3s ease; /* Smooth transition on focus */
}

input:focus,
textarea:focus {
  border-bottom-color: #007aff; /* Change underline color on focus */
}

textarea {
  resize: none;
  height: 100px; /* Maintain height */
}

.send-message-button {
  background-color: #52a6e1;
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold; /* Bold text */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.send-message-button:hover {
  background-color: #0056cc;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .contact-card {
    flex-wrap: wrap; /* Allows wrapping for smaller screens */
    gap: 30px; /* Adjusts spacing for better alignment */
  }
}

@media (max-width: 768px) {
  .contact-card {
    flex-direction: column; /* Stacks cards vertically */
    gap: 20px;
  }

  .contact-info-card,
  .contact-form-card {
    flex: 1 1 100%; /* Full width for smaller screens */
  }
}
