/* Video Container */
.video-container {
  position: relative;
  width: 100%;
  height: 50vh; /* Set 30% of the viewport height */
  overflow: hidden;
}
.video-container img {
  width: 100%;
  height: 100%;
  object-fit: fill; /* Ensures the image scales proportionally and covers the container */
}
  
  .background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .video-overlay-image {
    position: absolute;
    bottom: 10%;
    left: 5%;
    width: 150px; /* Adjust image size */
    z-index: 2;
  }
  
  .video-overlay-image img {
    width: 100%;
    height: auto;
  }
  
  /* Product Grid */
  .product-page {
    padding: 20px;
    text-align: center;
  }
  
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
  }
  
  .product-grid .card-container {
    margin: 0 auto;
  }
  .card-wrapper {
    position: relative;
  }
  
  .sold-out {
    pointer-events: none; /* Disable clicking for sold-out cards */
  }
  
  .sold-out-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    border-radius: 10px;
    z-index: 2; /* Ensure overlay is above the card content */
  }
  
  @media (max-width: 768px) {
    .video-container {
      height: 30vh; /* Increase video height for smaller screens */
    }
  
    .video-overlay-image {
      width: 100px; /* Reduce the size of the overlay image on smaller screens */
    }
  }