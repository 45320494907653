/* General Container Styling */
.form-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  background-color: #f9f9f9;
  padding: 20px;
}

.form-note {
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: #555;
}

/* Form Layout */
.form-row textarea {
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
  width: 100%; /* Ensures it spans the full width */
  min-height: 80px; /* Sets a reasonable minimum height */
  resize: vertical; /* Allows vertical resizing */
}

/* Focus Effect */
.form-row textarea:focus {
  border-color: #007BFF;
  
}

/* Disabled State */
.form-row textarea:disabled {
  background-color: #fff; /* Light gray background when disabled */
  /* cursor: not-allowed; */
  /* color: #aaa; Light text color for disabled state */
}
.form {
  background: #fff;
  padding: 20px 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  width: 100%;
  margin-top: 10px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.form-row label {
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
  display: block;
}

/* Input Fields Styling */
.form-row input,
.form-row select {
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  box-sizing: border-box;
}

/* Focus Effect */
.form-row input:focus,
.form-row select:focus {
  border-color: #007BFF;
}

/* Error Messages */
.error {
  font-size: 12px;
  color: #f44336;
  margin-top: 5px;
}

/* Button Styling */
.submit-btn {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.submit-btn:hover {
  background-color: #45a049;
}

/* Field Layout - Different widths for fields */
.full-width {
  flex: 1 1 100%; /* Full width for large fields */
}

.half-width {
  flex: 1 1 calc(50% - 20px); /* Two fields per row for smaller fields */
}

.small-width {
  flex: 1 1 calc(33% - 20px); /* Three small fields per row if needed */
}

/* OTP Popup Container */
.otp-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* OTP Popup Content */
.otp-popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 90%;
  max-width: 400px;
}

/* Input Field */
.otp-popup-content input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

/* Buttons Container */
.otp-popup-buttons {
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

/* Submit Button */
.submit-btn {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #45a049;
}

/* Cancel Button */
.close-btn {
  background-color: #f44336;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

.close-btn:hover {
  background-color: #d32f2f;
}

/* Error Message */
.error {
  color: red;
  font-size: 14px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .otp-popup-content {
    width: 90%;
    max-width: 300px;
  }
  .otp-popup-buttons button {
    font-size: 14px;
    padding: 8px 15px;
  }
}


/* Responsive Layout for Mobile View */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  .full-width,
  .half-width,
  .small-width {
    flex: 1 1 100%; /* All fields full width on mobile */
  }

  .form-container {
    padding: 10px;
  }

  .form {
    width: 100%;
    margin-top: 20px;
  }
}


