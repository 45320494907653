.features-container {
    padding: 40px 20px;
    text-align: justify;
    background-color: #ffffff;
  }
  
  .features-header h2 {
    font-size: 2.5rem;
    color: #333333;
    margin-bottom: 10px;
  }
  
  .features-header h2 .highlight {
    color: #007aff;
  }
  
  .features-header p {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 30px;
    text-align: center;
  }
  
  .features-row {
    display: flex;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
    margin: 40px 0;
  }
  
  .features-row.reverse {
    flex-direction: row-reverse; /* Reverse the order for alternating rows */
  }
  
  .features-image {
    flex: 1;
    max-width: 500px;
    
  }
  
  .features-image img {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .features-content {
    flex: 1;
    max-width: 500px;
    text-align: justify;
  }
  
  .features-content h3 {
    font-size: 1.8rem;
    color: #0056d2;
    margin-bottom: 15px;
  }
  
  .features-content ul {
    list-style: none;
    padding: 0;
    margin: 10;
  }
  
  .features-content ul li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 1.1rem;
    color: #333;
    text-align: justify;
  }
  
  .features-content ul li .icon {
    margin-right: 10px;
    font-size: 1.5rem;
    color: #0056d2;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .features-row {
      flex-direction: column; /* Stack vertically on mobile */
    }
  
    .features-row.reverse {
      flex-direction: column; /* Ensure consistent stacking for reverse rows */
    }
  
    .features-content {
      text-align: justify;
    }
  
    .features-content ul li {
      justify-content: left;
    }
  }
  